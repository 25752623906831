<template>
  <div style="min-width: 1260px">
    <app-main ref="main">
      <!-- 顶部广告 -->
      <template v-slot:main-top>
        <div @click="adClick" class="ad" ref="ad"><img :src="adsimage.localimg" /></div>
      </template>
      <template v-slot:main-content>
        <!------------------------------- Step1 -------------录入收件人信息------------------------>
        <div class="step">
          <p class="stepTitle">Step1：录入收件人信息（推荐绑定店铺“智能发货”或“手工填写）</p>
          <div class="step-body">
            <div @click="stepTwoItemClick(item, index)" v-for="(item, index) in stepTwo" :key="index" class="step-body-item" :class="{ active: stepTwoActive == index }">
              <img class="logImg" :src="item.icon" />&nbsp;&nbsp; <div class="imgName">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="step" v-show="autoSmart">
          <p class="stepTitle">Step2：选择店铺</p>
          <div class="step-body">
            <div @click="stepTwoItemClickShop(item, index)" v-for="(item, index) in stepTwoShop" :key="index" class="step-body-item" :class="{ active: stepTwoActiveShop == index }">
              <img class="logImg" :src="item.icon" /> <div class="imgName">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- 录入收件人信息 '绑定淘宝', '绑定拼多多', :autoSmart="autoSmart?false:tuue"'一键发货'-->
        <!---------------------------- 模板导入 ---------------------------->
        <el-collapse-transition>
          <div v-show="templateImport" class="selectWay templateImport">
            <div class="upload-left">
              <el-upload class="upload" drag action accept=".xls, .xlsx, .csv, .xlsm, .xltx" :show-file-list="false" :http-request="uploadFile" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text"> 将文件拖到此处，或
                  <em>点击上传</em> <br />
                  <span class="upload-span">仅支持CSV、XLS、XLSM、XLTX、XLSX文件</span>
                  <p></p>
                  <span class="upload-span"> 导入地址过多请分批下单，单次条数控制在 <em>100</em>条左右 </span>
                </div>
              </el-upload>
            </div>
            <div class="upload-right">
              <p>注意事项:</p>
              <p>1.从官方平台导出的订单模板，无需任何修改，直接上传即可</p>
              <p>
                2.可选择下载本平台指定模板格式上传，点击
                <a :href="url + '/uploads/地址模板.xlsx'">下载模板</a>
              </p>
              <p>3.仅支持CSV、XLS、XLSM、XLTX、XLSX文件</p>
              <!-- <p>4.为不影响您的正常发货，请留意各省市快递停发区域。 <span @click="seeSending">点击查看停发区域</span></p> -->
              <p>4.导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)</p>
            </div>
          </div>
        </el-collapse-transition>
        <!----------------------------绑定店铺 ------店铺导入 ---------------------------->
        <el-collapse-transition>
          <div v-show="storeImport" class="selectWay">
            <div class="store-select">
              <span class="titleshop">{{ bindTitle }}&nbsp;</span>
              <el-select v-model="shopValue" placeholder="请选择绑定的店铺">
                <el-option v-for="item in shopOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <span style="margin-left: 16px; color: #1890ff; cursor: pointer" @click="jumpToStoreBind">绑定店铺</span>
            </div>
            <div class="flag-select">
              <span>标记筛选：&nbsp;</span>
              <el-radio-group v-model="radio">
                <el-radio :label="6">全部</el-radio>
                <!-- <el-radio :label="0">
                  <i class="el-icon-s-flag" style="color: #ccc; font-size: 18px"></i>
                </el-radio> -->
                <el-radio :label="1">
                  <i class="el-icon-s-flag" style="color: red; font-size: 18px"></i>
                </el-radio>
                <el-radio :label="2">
                  <i class="el-icon-s-flag" style="color: #ebe534; font-size: 18px"></i>
                </el-radio>
                <el-radio :label="3">
                  <i class="el-icon-s-flag" style="color: #34eb4f; font-size: 18px"></i>
                </el-radio>
                <el-radio :label="4">
                  <i class="el-icon-s-flag" style="color: #3452eb; font-size: 18px"></i>
                </el-radio>
                <el-radio :label="5">
                  <i class="el-icon-s-flag" style="color: #b434eb; font-size: 18px"></i>
                </el-radio>
              </el-radio-group>
            </div>
            <div class="selectWay">
              <!-- 标题 -->
              <div class="list-title">
                <div>
                  <span class="span-one">点击获取订单以获取订单</span>
                  <span class="span-two">
                    共上传
                    <span class="span-three">
                      &nbsp;
                      <b style="color: red; font-size: 16px">{{ addressList.length }}&nbsp;</b> </span
                    >条数据
                  </span>
                </div>
                <div>
                  <el-button @click="getOrderListByStoreNametb" :disabled="flag">{{ bindTitle }}订单获取</el-button>
                  <el-button @click="nextStep2" type="primary">信息正确,下一步</el-button>
                </div>
              </div>
              <!-- --------------------------订单展示表格 ---------------------------->
              <el-table :header-cell-style="{ background: '#fafafa' }" :data="addressList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" style="width: 100%">
                <el-table-column label="序号" min-width="5%">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="order" label="订单编号" min-width="10%"></el-table-column>
                <el-table-column prop="nick" label="旺旺名" min-width="10%"></el-table-column>
                <el-table-column prop="title" label="商品名称" min-width="16%"></el-table-column>
                <el-table-column prop="name" label="收货人姓名" min-width="10%"></el-table-column>
                <el-table-column prop="phone" label="手机号" min-width="10%"></el-table-column>
                <el-table-column prop="address" label="收货地址" min-width="30%">
                  <template slot-scope="scope">{{ scope.row.province }} {{ scope.row.area }} {{ scope.row.city }} {{ scope.row.detail }}</template>
                </el-table-column>
                <el-table-column label="操作" min-width="10%">
                  <template slot-scope="scope">
                    <el-button size="mini" @click="handleOrderListEdit(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleOrderListDelete(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="handleCurrentChange" background class="addressListPage" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="addressList.length"></el-pagination>
            </div>
          </div>
        </el-collapse-transition>
        <!-------------------------------一键发货填写---------&& addForm.address != ''------------------ -->
        <textarea type="text" id="cj-order-text-input" style="display: none" ref="seTxt1"></textarea>
        <el-collapse-transition>
          <div id="cj-order-sd-btn" v-if="handWrittenAuto" class="selectWay">
            <!-- 地址格式一 -->
            <div class="addressOne">
              <el-form :mode="addForm" ref="addFormRefs" class="addressOne-l">
                <!-- {undefined{scope.row.state == 0?“已完成”:scope.row.state == 1?“未完成”:‘处理中’}}  -->
                <el-form-item prop="address">
                  <el-input
                    type="textarea" class="textarea"
                    @focus="inputInput($event)" ref="seTxt2" :autosize="{ minRows: 6 }"
                    v-model="addForm.address" id="cj-order-text" @input="inputInput($event)" placeholder="
8888886688055, 湖北省 武汉市 江汉区 解放大道392号, 张三,15100009999

订单号, 地址, 姓名，电话，（中间用逗号分隔，回车键换行，一行一条~）

订单号，地址, 姓名，电话，

导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)。" ></el-input>
                </el-form-item>
                <el-form-item v-if="addForm.address">
                  <el-button type="primary" id="cj-order-text-btn" size="medium" @click="disAddres()" :disabled="flag"> 确认添加</el-button>
                  <el-button @click="resetForm('addForm')">重置</el-button>
                </el-form-item>
              </el-form>
              <!--  addAddressOne,-->
              <div class="addressOne-r">
                <p>一键发货功能使用说明:</p>
                <p>1、工作方式：请先观看插件安装，并观看跟自己相关的平台视频。</p>
                <p>
                  2、该批量发货方式需要先
                  <span @click="crxClick()">
                    <b style="color: orange; font-size: 20px">下载安装插件</b>
                  </span>
                  <span @click="jdClick()">查看视频教程</span>
                </p>
                <p>
                  3、京东一键下单发货教程
                  <span @click="jdClick()">查看视频教程</span>
                </p>
                <p>
                  4、淘宝菜鸟一键下单发货教程
                  <span @click="jdClick()">查看视频教程</span>
                </p>
                <p>
                  5、抖店一键下单发货教程查
                  <span @click="jdClick()">查看视频教程</span>
                </p>
                <!-- <p>6、拼多多一键下单发货教程 <span @click="pddClick()">查看视频教程</span></p> -->
                <!-- <p>7、为不影响您的正常发货，请留意各省市快递停发区域 <span @click="seeSending">点击查看停发区域</span></p> -->
                <p>6、导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)。</p>
              </div>
            </div>
            <div class="NotTitle">{{ model.NotTitle }}</div>
            <div class="btns"></div>
          </div>
        </el-collapse-transition>
        <!------------------------------- 手工填写--------------------------------- -->
        <el-collapse-transition>
          <div v-show="handWritten" class="selectWay">
            <el-tabs @tab-click="handleClick" value="first">
              <!-- 地址格式一 -->
              <el-tab-pane label="地址格式一" name="first">
                <div class="addressOne">
                  <el-form :mode="addForm" ref="addFormRefs" class="addressOne-l">
                    <el-form-item prop="address">
                      <el-input type="textarea" class="textarea" :autosize="{ minRows: 6 }" v-model="addForm.address"
                        placeholder="订单号，地址, 姓名，电话，（中间用逗号分隔，回车键换行，一行一条~）

订单号，地址, 姓名，电话，

订单号，地址, 姓名，电话，

导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)" ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" size="medium" @click="disAddres2()" :disabled="flag2">确认添加</el-button>
                      <el-button @click="resetForm('addForm')">重置</el-button>
                    </el-form-item>
                  </el-form>
                  <div class="addressOne-r">
                    <p>注意事项:</p>
                    <p>1、订单号, 地址, 姓名，电话， 按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。</p>
                    <p>2、如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”</p>
                    <!-- <p>3、为不影响您的正常发货，请留意各省市快递停发区域。 <span @click="seeSending">点击查看停发区域</span></p> -->
                    <br />
                    <p>示例:</p>
                    <p>2292888888888888055, 湖北省 武汉市 江汉区 解放大道392号, 张三,15100009999</p>
                  </div>
                </div>
              </el-tab-pane>
              <!-- 地址格式二 -->
              <el-tab-pane label="地址格式二" name="second">
                <div class="addressTwo">
                  <div class="addressTwo-l">
                    <el-form :inline="true" :model="arsObj">
                      <el-form-item>
                        <el-input v-model="arsObj.order" type="text" placeholder="订单编号" style="width: 630px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="arsObj.province" type="text" placeholder="输入省" style="width: 200px"> </el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="arsObj.city" type="text" placeholder="输入市" style="width: 200px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="arsObj.area" type="text" placeholder="输入县/区" style="width: 210px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="arsObj.detail" type="text" placeholder="详细地址，街道，楼栋等" style="width: 630px"> </el-input>
                      </el-form-item>
                      <br />
                      <el-form-item>
                        <el-input v-model="arsObj.name" type="text" placeholder="姓名" style="width: 300px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="arsObj.phone" type="text" placeholder="手机号" style="width: 320px"></el-input>
                      </el-form-item>
                    </el-form>
                    <el-button @click="addAddressTwo" type="primary" size="medium">确认添加</el-button>
                  </div>
                  <div class="addressTwo-r">
                    <p>注意事项:</p>
                    <p>1.订单号, 姓名，电话，地址 按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。</p>
                    <p>2.如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”</p>
                    <!-- <p>3.为不影响您的正常发货，请留意各省市快递停发区域。 <span @click="seeSending">点击查看停发区域</span></p> -->
                    <br />
                    <p>示例:</p>
                    <p>2292888888823888055, 湖北省 武汉市 江汉区 解放大道392号, 张三,15300009999</p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="NotTitle">{{ model.NotTitle }}</div>
          </div>
        </el-collapse-transition>
        <!---------------------------- 收货人信息列表 ---------------------------->
        <div v-if="listTableFlag || addForm.address" class="selectWay">
          <!-- 标题 -->
          <div class="list-title">
            <div>
              <span class="span-one">根据导入内容，核对收件人信息</span>
              <span class="span-two">
                共上传
                <span class="span-three" style="color: red">
                  <b>&nbsp;{{ addressList.length }}&nbsp;</b> </span
                >条数据
              </span>
            </div>
            <div>
              <el-button @click="nextStep" type="primary">信息正确,下一步</el-button>
            </div>
          </div>
          <!-- 收货人表格 -->
          <el-table :header-cell-style="{ background: '#fafafa' }" :data="addressList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" style="width: 100%">
            <div v-for="(item, i) in addressList" :key="i">{{ item }}</div>
            <el-table-column label="序号" min-width="5%">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" min-width="16%">
              <template slot-scope="scope">
                <span v-if="scope.row.order != ''">{{ scope.row.order }}</span>
                <span v-else style="color: red">订单号不能为空</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="address" label="收货地址" min-width="30%"> </el-table-column> -->
            <el-table-column label="收货地址" min-width="38%">
              <template slot-scope="scope">
                <span v-if="scope.row.province != '' || scope.row.city != '' || scope.row.area != '' || scope.row.detail != ''">{{ scope.row.province }} {{ scope.row.city }}{{ scope.row.area }} {{ scope.row.detail }}</span>
                <span v-else style="color: red">收货人地址不能为空</span>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" min-width="10%">
              <template slot-scope="scope">
                <span v-if="scope.row.name != ''">{{ scope.row.name }}</span>
                <span v-else style="color: red">收货人不能为空</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" min-width="10%">
              <template slot-scope="scope">
                <span v-if="scope.row.phone && scope.row.phone.length === 11">{{ scope.row.phone }}</span>
                <span v-else-if="scope.row.phone.length < 11">
                  <p style="color: red">
                    {{ scope.row.phone }}
                    <br />手机号格式有误
                  </p>
                </span>
                <span v-else style="color: red">
                  {{ scope.row.phone }}
                  <br />手机号格式有误
                </span>
              </template>
            </el-table-column>
            <el-table-column label="上家单号" min-width="10%">
              <template slot-scope="scope">
                <div><input type="text" @blur="blur($event, scope.$index + (currentPage - 1) * pageSize + 1 - 1)" style="width: 137px" /></div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="10%">
              <template slot-scope="scope" style="display: flex">
                <el-button size="mini" @click="handleEdit(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index + (currentPage - 1) * pageSize + 1, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="handleCurrentChange" background class="addressListPage" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="addressList.length"> </el-pagination>
        </div>
        <!-- --------------------------编辑表格Dialog ---------------------------->
        <el-dialog title="编辑收货人信息(手动录入)" width="30%" :visible.sync="dialogFormVisible">
          <el-form class="editForm" :model="editObj" :rules="rules" ref="editObj">
            <el-form-item label="订单编号">
              <el-input v-model="editObj.order" clearable></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名">
              <el-input v-model="editObj.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="editObj.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable> </el-input>
            </el-form-item>
            <el-form-item label="收货地址">
              <el-input v-model="editObj.province" clearable></el-input>
              <el-input v-model="editObj.city" clearable></el-input>
              <el-input v-model="editObj.area" clearable></el-input>
              <el-input v-model="editObj.detail" type="textarea" clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="dialogFormYes">确 定</el-button>
          </div>
        </el-dialog>
        <!-- --------------------------Step3 --Step3：选择数据来源平台-------------------------->
        <div class="step" ref="stepThree" v-show="autoPlatform">
          <p class="stepTitle">Step3：选择单号类型</p>
          <div class="step-body">
            <div @click="stepOneItemClick(item.id)" v-for="item in stepOne" :key="item.id" class="step-body-item2" :class="{ active: stepOneActive == item.id }">
              <img class="logImg" :src="item.icon" />
              <div class="imgName">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- --------------------------Step3-----提交订单--------------------- -->
        <div class="step three" ref="stepThrees">
          <div class="step-body step-three">
            <!-- 左边 -->
            <div class="step-body-left">
              <!-- 仓库 -->
              <div class="screening">
                <div class="title">仓库:</div>
                <ul class="list">
                  <li @click="storeItemClick(item.id, index)" :class="[storeActive == (item.id || 0) ? 'screening-active' : '']" class="item" v-for="(item, index) in storeList" :key="index">
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <!-- 排序 -->
              <div class="screening">
                <div class="title">排序:</div>
                <ul class="list">
                  <li @click="sortItemClick(index)" :class="{ 'screening-active': sortActive == index }" class="item" v-for="(item, index) in sortList" :key="index">
                    {{ item }}
                  </li>
                </ul>
              </div>
              <!-- 礼品列表 -->
              <div class="goods">
                <div class="goods-item" v-for="(item, index) in goodsLists.length !== 0 ? goodsLists : goodsList" :key="index">
                  <div class="image"><img :src="item.goods_images" /></div>
                  <div class="btn" @click="addGoods(item)">确认礼品</div>
                  <div class="price">￥{{ item.goods_price }}</div>
                  <div class="message">
                    <span style="width: 150px; overflow: hidden">{{ item.goods_name }}</span>
                    <span>{{ item.goods_weight }}g</span>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <el-pagination background @current-change="goodsHandleCurrentChange" :current-page="goodsCurrentPage" :page-size="goodsPageSize" layout="total, prev, pager, next, jumper" :total="totalPage"></el-pagination>
            </div>
            <!-- 右边 -->
            <div class="step-body-right">
              <div class="table-box">
                <!-- 表格 -->
                <el-table :header-cell-style="{ background: '#F5F5F5', color: 'black', fontWeight: 'normal' }" :data="lipinList" style="width: 100%">
                  <el-table-column label="礼品图">
                    <template slot-scope="scope">
                      <img :src="scope.row.goods_images" width="88px" height="88px" />
                    </template>
                  </el-table-column>
                  <el-table-column prop="goods_name" label="礼品名称"></el-table-column>
                  <el-table-column prop="goods_price" label="单价"></el-table-column>
                  <el-table-column label="数量">
                    <template>
                      <!-- <template slot-scope="scope"> -->
                      <el-input-number v-model="num" :min="1" :max="1" style="width: 100%" size="mini"> </el-input-number>
                      <!-- <el-input-number v-model="num" @change="(value) => handleChange(value, scope)" :min="1" :max="1" disabled style="width: 100%" size="mini"></el-input-number> -->
                    </template>
                    <!-- {{ numbers }} -->
                    <!-- <template slot-scope="scope"><span>{{scope.row.wechatapp_switch}}</span></template> -->
                    <!-- <template slot-scope="scope"><span>{{(scope.row.wechatapp_switch)/scope.row.wechatapp_switch}}</span></template> -->
                  </el-table-column>
                  <el-table-column label="重量(kg/g)">{{ totalWeigth }}</el-table-column>
                  <el-table-column prop label="发货仓库">
                    <template slot-scope="scope">
                      <span v-if="scope.row.storage_name != ''">{{ scope.row.storage_name }}</span>
                      <span v-else style="color: #e07f7f">该商品在仓库中未填写仓库名</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="小计">{{ Number(littleTotal).toFixed(2) }}</el-table-column>
                </el-table>
              </div>
              <!-- 提交订单 -->
              <!-- {{storageOptions}}----111 -->
              <div class="sub-order">
                <el-form :model="goodsItem">
                  <div style class="elIn">
                    <el-form-item label="备注信息:">
                      <el-input v-model="remarks" width="430px"></el-input>
                    </el-form-item>
                    <el-form-item label="运送方式:">
                      <el-select data-v-11c1012b v-model="storageValue" placeholder="请选择快递" @change="storageSelectChange" v-if="storageValue != undefined || shows">
                        <el-option v-for="(item, index) in storageOptions" :key="index" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="发货地址:">
                      <el-select data-v-11c1012b v-model="areaListValue" placeholder="请选择发件人" @change="areaSelectChange">
                        <el-option v-for="item in areaList" :key="item.id" :label="item.name + ' ' + item.send_phone" :value="item.id"></el-option>
                      </el-select>
                      <el-dialog title="发货人地址" :visible.sync="userInfoShow" center>
                        <!-- :model="form" -->
                        <el-form :model="userInfo" :label-position="labelPosition" label-width="100px" style="display: flex; flex-direction: column; align-items: center">
                          <el-form-item label="姓名：" style="padding: 10px 0">
                            <el-input size="medium" placeholder="请输入姓名" v-model.trim="userInfo.send_name"></el-input>
                          </el-form-item>
                          <el-form-item label="手机：" style="padding: 10px 0">
                            <el-input size="medium" placeholder="请输入手机号" v-model.trim="userInfo.send_phone"></el-input>
                          </el-form-item>
                          <el-form-item label="地址：" style="padding: 10px 0">
                            <el-cascader size="large" :options="options" style="width: 450px" placeholder="请选择城市" @change="cityChange" v-if="isShowInfo"></el-cascader>
                          </el-form-item>
                          <el-form-item label="详细地址：" style="padding: 10px 0">
                            <el-input size="medium" placeholder="请输入详细地址" v-model.trim="userInfo.send_address"> </el-input>
                          </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                          <el-button data-v-11c101ee @click="userInfoShow = false">取 消</el-button>
                          <el-button data-v-11c101ee type="primary" @click="btns">确 定</el-button>
                        </div>
                      </el-dialog>
                    </el-form-item>
                  </div>
                  <span class="xjdz" @click="userInfoShow = true">新建发件地址</span>

                  <div class="elitem">
                    <el-form-item label="收件人数:">
                      <span>{{ addressList.length }}</span>
                    </el-form-item>
                    <el-form-item label="礼品总额:">
                      <span class="sub-order-color">￥{{ Number(totalGifts).toFixed(2) }}</span>
                    </el-form-item>
                    <el-form-item label="礼品运费:">
                      <span>￥{{ Number(freight).toFixed(2) }}</span>
                    </el-form-item>
                    <el-form-item label="应付金额:">
                      <span class="sub-order-color">￥{{ Number(payable).toFixed(2) }}</span>
                    </el-form-item>
                  </div>
                  <div class="btns">
                    <span class="sub-btn" @click="placeOrder" :disabled="flag">提交订单 ￥{{ Number(payable).toFixed(2) }}</span>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <!-- 停发区域Dialog   {{Number(goods.salePrice).toFixed(2)}} @formSubmit='translateText'-->
        <stop-sending :showStopSending="showStopSending" @closeStopSending="closeStopSending" :stopStoreList="stopStoreList" />
        <!-- 付款Dialog -->
        <pay-dialog :dialogShow="dialogShow" @closePayDialog="closePayDialog" :name="goodsItem.goods_name" :price="payable" :money="money" :orderId="orderId" />
      </template>
    </app-main>
  </div>
</template>
<script>
import { regionData, CodeToText } from 'element-china-area-data'
import AddressParse from 'zh-address-parse' // 地址解析插件
import AppMain from 'components/baseStructure/AppMain'
import PayDialog from 'components/payDialog/PayDialog'
import StopSending from 'components/stopSending/StopSending'
// eslint-disable-next-line no-unused-vars
import { addArea, getAllStorage, getsuperadsbytag, choosePlatform, getAllGoods, getDisAddres, goodsSearchGs, createOrder, getAreaList, getUserMoney, importExcel, getStoreName, getTaoBaoOrderList, getAllOrderList, resolve } from 'network/api'
export default {
  name: 'Purchase',
  components: { AppMain, PayDialog, StopSending },
  inject: ['mainReload'],
  data() {
    var checkNickname = (rule, value, cb) => {
      const regNickname = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (regNickname.test(value)) {
        cb()
      }
      cb(new Error('手机号不合法！'))
    }
    return {
      flag: false,
      flag2: false,
      shows: true,
      item: null,
      labelPosition: 'right',
      isShowInfo: true,
      userInfo: {
        send_name: '',
        send_phone: '',
        send_address: '',
        send_city: '',
        send_province: '',
        send_town: ''
      },
      options: regionData,
      userInfoShow: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkNickname, trigger: 'blur' }
        ]
      },
      autoSmart: false, // 智能发货
      innerVisible: false,
      addForm: { address: '' },
      model: {
        NotTitle: '【注意】如因虚拟号提示手机格式错误，可忽略不管直接下单。【温馨提示】导入地址过多请分批下单，单子过多可能会造成卡单、无法出单等异常情况(单次条数控制在100条左右)\r\n【注意】个别订单中的包裹会因疫情或偏远原因无法获取单号，平台会关闭对应订单并退款，可更换其他快递或联系买手修改地址再进行下单。 '
      },
      stepOne: [
        { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
        { id: 2, key: 2, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
        { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
        { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
        { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
      ],
      // stepTwo: ['一键发货', '模板导入', '手工填写', '绑定淘宝店铺', '绑定拼多多', '绑定京东', '绑定抖音'],
      stepTwo: [
        { name: '智能发货', icon: require('@/assets/icons/smart.png') },
        { name: '手工填写', icon: require('@/assets/icons/sd.png') },
        { name: '一键发货', icon: require('@/assets/icons/a.png') },
        { name: '模板导入', icon: require('@/assets/icons/mb.png') }
      ],
      stepTwoShop: [
        { name: '淘宝店铺', icon: require('@/assets/icons/tb.png') },
        { name: '京东店铺', icon: require('@/assets/icons/jd.png') },
        { name: '拼多多店铺', icon: require('@/assets/icons/pdd.png') },
        { name: '抖音店铺', icon: require('@/assets/icons/dy.png') }
      ],
      // 淘宝菜鸟面单/阿里菜鸟面单/抖音抖店面单/京东无界面单/拼多多面单/其他面单
      stepOneActive: null,
      stepTwoActive: 1,
      stepTwoActiveShop: -1, // 发货店铺
      templateImport: false, // 控制模板导入相关显示1582812 7592
      storeImport: false, // 控制店铺导入相关显示
      handWritten: true, // 控制手工填写相关显示
      handWrittenAuto: false, // 控制一键发货填写相关显示
      /* 以下为地址数据 */
      textareaAddressValue: '',
      textareaadd: '',
      addressList: [], // 收件人列表
      enteringList: [], // 手动录入地址
      addressListTotal: null,
      currentPage: 1, // 当前页
      pageSize: 20, // 每页多少条
      totalPage: 0, // 总商品数
      listTableFlag: false, // 控制地址列表是否显示
      arsObj: {},
      dialogFormVisible: false, // 控制编辑Dialog显示
      editObj: {
        order: undefined,
        name: undefined,
        phone: undefined,
        // address: undefined
        province: undefined,
        area: undefined,
        city: undefined,
        detail: undefined
      }, // 编辑地址对象
      addressListItemIndex: null /* 以下为礼品数据 */, // 选中地址的索引
      /* 以上为地址数据 */
      storeList: [], // 仓库列表
      stopStoreList: [], // 停发仓库列表
      storeListItem: {}, // 仓库对象
      storeListItemName: '', // 仓库名字 添加礼品列表需要用到
      storeListItemId: null, // 仓库id
      sortList: ['默认', '价格从高到低', '销量从高到低', '重量从重到轻'],
      storeActive: 0,
      sortActive: 0,
      goodsPageSize: 16,
      goodsCurrentPage: 1,
      goodsList: [], // 礼品列表
      goodsItem: {}, // 礼品详情
      dataFlag: false, // true代表选择了礼品, false没有选择礼品
      storageValue: null, // 运送方式select绑定的数据
      storageOptions: [], // 运送方式option绑定的数据
      fprice: '', // 运费
      lipinList: [],
      /* 以上为礼品数据 */
      dialogShow: false, // 付款Dialog
      showStopSending: false, // 停发区域
      remarks: '', // 备注
      areaListValue: '',
      areaList: [], // 发件人列表
      area_id: '', // 地址id
      oid: '',
      oaid: '', // pdd 使用的
      platform: '', // 平台
      autoPlatform: true, // 智能发货平台隐藏
      platform2: '', // 平台排序号
      money: '', // 账户余额
      orderId: '', // 订单id
      goodsType: '', // 商品排序标识
      shopValue: '', // 选择的店铺value
      shopValueKey: '', // value中的 key pdd7312624900
      shopOptions: [],
      radio: 6, // 店铺导入的flag
      orderList: [], // 店铺导入获取的订单列表
      newList: [],
      newList1: [],
      url: '',
      nav: 0,
      numbers: 1,
      order_from: '', // 下单方式
      lurufangshi: '', // 录入方式 -1=一键,0=模板,1=手动,2=tb店,3=pdd店,4=jd店,5=dy店',
      adsimage: '',
      clickhref: '',
      orderFrom: '', // 绑定 店铺 id 例如 dy10086
      num: 1,
      tblink: '',
      shopId: null,
      timer: null,
      goodsLists: [],
      bindTitle: '淘宝店铺',
      keys: ''
    }
  },

  beforeDestroy() {},
  created() {
    this.lurufangshi = -1
    this.allGoods()
    // 顶部广告
    getsuperadsbytag({ tagname: 'purchase' }).then((res) => {
      this.adsimage = res.data.adslist[0]
      this.clickhref = res.data.adslist[0].clickhref
    })
    // 获取所有仓库信息
    getAllStorage().then((res) => {
      this.storeList = res.data
      // 向数组中添加一个'全部'
      const i = { name: '全部' }
      if (this.storeList) {
        this.storeList.unshift(i)
      }
    })
    // this.disAddres()
    this.url = this.$url
    // 获取所有商品
    if (this.$route.query.shopId && this.$route.query.item !== '[object Object]') {
      // console.log(this.$route.query)
      const id = this.$route.query.shopId.goods_id
      this.storeActive = id
      this.storeListItemId = id // 赋值
      this.stepTwoActive = 1
      this.stepOneItemClick1(this.$route.query.shopId.storage_id)
      this.firstShow() // 选择手动填写
      this.addGoods(this.$route.query.item) // 添加商品
      goodsSearchGs({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType,
        page: this.goodsCurrentPage,
        op: 'shop',
        key: this.keys
      }).then((res) => {
        this.goodsLists = res.data.data
        this.totalPage = res.data.total
        this.goodsPageSize = res.data.per_page
      })
      // dubbger
      choosePlatform(this.platform, this.keys).then((res) => {
        this.storeActive = res.data[0].id
        this.storeList = res.data
        this.stopStoreList = res.data
        this.storeListItem = this.storeList[0] // 初始化仓库对象
        // this.storeListItemId = this.storeList[0].id
        // this.storeListItemName = this.storeList[0].name // 获取到仓库名
        if (res.code === 1 && res.data.length !== 0) {
          // this.storeActive = res.data[0].id
          this.storeList = res.data
          res.data.forEach((item, index) => {
            if (
              // eslint-disable-next-line eqeqeq
              item.name == this.$route.query.item.storage_name ||
              this.$route.query.shopId.goods_id === item.id
            ) {
              const v = {}
              v.value = item.id
              v.label = item.deliver_name + ' / ' + item.deliver_price + '元'
              v.freight_price = item.deliver_price
              this.fprice = item.deliver_price
              this.storageOptions = []
              this.storageOptions.push(v)
              this.storageValue = v.label
            }
          })
        } else {
          return this.$message.warning('请先选择仓库')
        }
      })
    }
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    blur(e, index) {
      this.addressList[index].lp_reissue_sn = e.target.value
    },
    stepOneItemClick1(item) {
      this.stepOneActive = item
      this.shopValue = ''
      switch (item) {
        case 0:
          this.platform = 'tb_switch'
          this.platform2 = 0
          break
        case 1:
          this.platform = 'ali_switch'
          this.platform2 = 1
          break
        case 2:
          this.platform = 'jd_switch'
          this.platform2 = 2
          break
        case 3:
          this.platform = 'pinxixi_switch'
          this.platform2 = 3
          break
        case 4:
          this.platform = 'dy_switch'
          this.platform2 = 4
          break
        case 5:
          this.platform = 'others_switch'
          this.platform2 = 5
          break
      }
    },
    firstShow() {
      this.handWritten = true
      this.handWrittenAuto = false
      this.addForm.address = ''
      // this.stepTwoActive = index
      this.stepTwoActiveShop = null
      this.stepOne = [
        { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
        { id: 1, key: 1, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
        { id: 2, key: 2, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
        { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
        { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
        { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
      ]
    },
    cityChange(v) {
      this.userInfo.send_province = CodeToText[v[0]]
      this.userInfo.send_city = CodeToText[v[1]]
      this.userInfo.send_town = CodeToText[v[2]]
    },
    btns() {
      // this.userInfoShow = false
      // const { city } = this.userInfo
      // this.userInfo.send_city = CodeToText[city[0]]
      // this.userInfo.send_province = CodeToText[city[1]]
      // this.userInfo.send_town = CodeToText[city[2]]

      for (const key in this.userInfo) {
        // 判断值是否为空
        if (!this.userInfo[key]) {
          this.$message({
            message: '请完善信息',
            type: 'warning'
          })
          return false
        }
      }
      // 验证详细地址  str.replace(/\s+/g,"");
      // if (!/^[\u4E00-\u9FA5,0-9]+$/.test(this.userInfo.send_address)) {
      //   this.$message({
      //     message: '请填写正确地址',
      //     type: 'warning'
      //   })
      //   return
      // }
      // 验证手机号
      if (!/^1[3|4|5|7|8][0-9]{9}$/.test(this.userInfo.send_phone)) {
        this.$message({
          message: '手机号码错误',
          type: 'warning'
        })
      } else {
        // 正确则添加地址
        // let {name,}
        addArea(this.userInfo).then((res) => {
          if (res.code === 1) {
            this.$message({ message: res.msg, type: 'success' })
            if (this.lipinList.length > 0) {
              getAreaList().then((res) => {
                if (res.code === 1) {
                  this.areaList = res.data
                  Object.keys(this.userInfo).forEach((key) => {
                    this.userInfo[key] = ''
                  })
                  // this.areaListValue = this.userInfo.send_address
                }
              })
              this.isShowInfo = false
              setTimeout(() => {
                this.isShowInfo = true
              }, 20)
            }
            this.userInfoShow = false
          }
        })
      }
    },
    inputInput(e) {
      // const thet = this
      this.flag = true
      if (e.length >= 41) {
        this.handWrittenAuto = true
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.disAddres()
        }, 1000)
        setTimeout(() => {
          this.flag = false
        }, 5000)
      } else if (e.length <= 40) {
        this.addForm.address = ''
        this.addressList = []
        this.listTableFlag = false
        return false
      }
      // this.$refs.import.$div.cliick()
    },
    // 选择数据来源平台点击
    stepOneItemClick(item) {
      this.stepOneActive = item
      this.shopValue = ''
      // this.stepTwoActive = null
      switch (item) {
        case 0:
          this.platform = 'tb_switch'
          this.platform2 = 0
          break
        case 1:
          this.platform = 'ali_switch'
          this.platform2 = 1
          break
        case 2:
          this.platform = 'jd_switch'
          this.platform2 = 2
          break
        case 3:
          this.platform = 'pinxixi_switch'
          this.platform2 = 3
          break
        case 4:
          this.platform = 'dy_switch'
          this.platform2 = 4
          break
        case 5:
          this.platform = 'others_switch'
          this.platform2 = 5
          break
      }
      // 点击平台不同获取仓库列表不同
      choosePlatform(this.platform, this.keys).then((res) => {
        if (res.code === 1 && res.data.length !== 0) {
          this.storeActive = res.data[0].id
          this.storeList = res.data
          this.stopStoreList = res.data
          this.storeListItem = this.storeList[0] // 初始化仓库对象
          this.storeListItemId = this.storeList[0].id
          this.storeListItemName = this.storeList[0].name // 获取到仓库名
          this.getGoodsSearchGs() // 初始化礼品列表
        } else {
          this.goodsList = null
          this.totalPage = 0
          this.goodsPageSize = 0
          this.storeList = null
          this.storeListItemName = null
        }
      })
    },
    // 获取所有商品请求
    allGoods() {
      getAllGoods({ page: this.goodsCurrentPage, op: 'shop' }).then((res) => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.goodsList = res.data.data
          this.totalPage = res.data.total
          this.goodsPageSize = res.data.per_page
        }
      })
    },
    // 商品筛选请求
    getGoodsSearchGs() {
      goodsSearchGs({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType,
        page: this.goodsCurrentPage,
        op: 'shop',
        key: this.keys
      }).then((res) => {
        this.goodsList = res.data.data
        this.totalPage = res.data.total
        this.goodsPageSize = res.data.per_page
      })
    },
    // 录入收件人信息的方式点击
    stepTwoItemClick(item, index) {
      this.autoPlatform = true // 智能发货隐藏平台
      this.stepOneActive = null
      this.shopValue = '' // 需要先选中平台
      this.addressList = [] // 订单地址置空
      this.stepTwoActive = null
      this.handWritten = false
      this.storeImport = false
      this.templateImport = false
      this.handWrittenAuto = false
      switch (item.name) {
        case '一键发货':
          this.handWrittenAuto = true
          this.autoSmart = false
          this.addForm.address = ''
          this.lurufangshi = '-1'
          this.stepTwoActive = index // 手动选中的平台名字
          this.stepTwoActiveShop = -1 // 店铺平台
          this.stepOne = [
            { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
            { id: 2, key: 2, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
            { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') }
          ]
          break
        case '模板导入':
          this.templateImport = true
          this.autoSmart = false
          this.addForm.address = ''
          this.lurufangshi = '0'
          this.stepTwoActive = index
          this.stepTwoActiveShop = null
          this.stepOne = [
            { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
            { id: 1, key: 1, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
            { id: 2, key: 2, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
            { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
            { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
            { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
          ]
          break
        case '手工填写':
          this.autoSmart = false
          this.handWritten = true
          this.addForm.address = ''
          this.lurufangshi = '1'
          this.stepTwoActive = index
          this.stepTwoActiveShop = null
          this.stepOne = [
            { id: 0, key: 0, name: '淘宝菜鸟面单', icon: require('@/assets/icons/tb.png') },
            { id: 1, key: 1, name: '阿里菜鸟面单', icon: require('@/assets/icons/al.png') },
            { id: 2, key: 2, name: '京东无界面单', icon: require('@/assets/icons/jd.png') },
            { id: 3, key: 3, name: '拼多多面单', icon: require('@/assets/icons/pdd.png') },
            { id: 4, key: 4, name: '抖音抖店面单', icon: require('@/assets/icons/dy.png') },
            { id: 5, key: 5, name: '其他面单', icon: require('@/assets/icons/qt.png') }
          ]
          break
        case '智能发货':
          this.storeActive = 1
          this.stepTwoActive = 0
          this.autoSmart = !this.autoSmart
          this.storeImport = !this.storeImport
          this.templateImport = false
          // eslint-disable-next-line eqeqeq
          if (this.autoSmart == false) {
            this.lurufangshi = '-1'
            this.stepTwoActive = 1 // 智能取消选择后 锁定到手动 0
            this.storeImport = false
            this.handWritten = true
            // eslint-disable-next-line eqeqeq
          } else if (this.autoSmart == true) {
            // this.stepTwoActiveShop = 0
            this.handWritten = false
            this.storeDyImport = false
            this.autoPlatform = false // 智能发货隐藏平台
          }
      }
    },
    // 绑定店铺方式点击导入方式 lurufangshi:-1=一键,0=模板,1=手动,2=tb店,3=pdd店,4=jd店,5=dy店',
    stepTwoItemClickShop(item, index) {
      this.bindTitle = item.name
      this.autoPlatform = false // 智能发货隐藏平台
      this.stepTwoActive = 0 // 点击店铺 锁定智能发货
      this.stepOneActive = null
      this.shopValue = '' // 需要先选中平台
      // this.stepTwoActive = null
      this.templateImport = false
      this.storeImport = true
      this.handWrittenAuto = false
      this.listTableFlag = false
      this.handWritten = false
      this.addForm.address = ''
      this.addressList = []
      switch (item.name) {
        case '淘宝店铺':
          this.lurufangshi = 2
          this.platform2 = 0
          this.stepTwoActive = 0
          this.stepTwoActiveShop = index
          this.stepOneActive = 0
          this.platform = 'tb_switch'
          break
        case '拼多多店铺':
          this.lurufangshi = 3
          this.platform2 = 3
          this.stepTwoActive = 0
          this.stepTwoActiveShop = index
          this.stepOneActive = 3
          this.platform = 'pinxixi_switch'
          break
        case '京东店铺':
          this.lurufangshi = 4
          this.platform2 = 1
          this.stepTwoActive = 0
          this.stepTwoActiveShop = index
          this.stepOneActive = 1
          this.platform = 'jd_switch'
          break
        case '抖音店铺':
          this.lurufangshi = 5
          this.platform2 = 4
          this.stepTwoActive = 0
          this.stepTwoActiveShop = index
          this.stepOneActive = 4
          this.platform = 'dy_switch'
          break
      }
      if (item.name === '淘宝店铺' || item.name === '拼多多店铺' || item.name === '京东店铺' || item.name === '抖音店铺') {
        this.getDianpu()
        choosePlatform(this.platform, this.keys).then((res) => {
          if (res.code === 1 && res.data.length !== 0) {
            this.storeActive = res.data[0].id
            this.storeList = res.data
            this.stopStoreList = res.data
            this.storeListItem = this.storeList[0] // 初始化仓库对象
            this.storeListItemId = this.storeList[0].id
            this.storeListItemName = this.storeList[0].name // 获取到仓库名
            // this.storeItemClick(this.storeList[0].id, 0)
            this.getGoodsSearchGs() // 初始化礼品列表
          }
        })
      }
    },
    getDianpu() {
      getStoreName({ bandType: this.platform2 }).then((res) => {
        if (res.code === 0) {
          this.shopOptions = []
        }
        if (res.code !== 1) {
          return false
        }
        this.shopOptions = []
        for (let i = 0; i < res.data.length; i++) {
          this.shopOptions.push({
            label: res.data[i][0], // 店铺的id 例如 dy10086
            value: res.data[i][1] // 此处value未定 等待后续确定
          })
        }
      })
    },
    jumpToStoreBind() {
      this.$router.push('storeBind')
    },
    resetForm() {
      this.addForm.address = ''
      this.addressList = []
      this.listTableFlag = false // 显示地址列表
    },
    // 替换直辖市
    addCheck(str) {
      const a = str.substr(0, 3)
      if (a === '北京北' || a === '天津天' || a === '重庆重' || a === '上海上') {
        const returnStr = str.substr(3) // 北京北
        let c = ''
        const d = a.substr(2) // 北
        c = a.slice(0, -1) // 北京
        c += '市' // 市
        c += d // 北
        c += returnStr //
        return c
      } else {
        return str
      }
    },
    disAddres() {
      if (this.addForm.address === '') {
        this.addressList = []
        return false
      }
      const addForm = this.addForm.address
      const selfMotion = 'selfMotion'
      getDisAddres({ address: addForm, op: selfMotion }).then((res) => {
        if (res.code !== 1) {
          return this.$notify.warning({ title: res.msg, offset: 90, duration: 3000 })
        } else {
          this.$notify.success({ title: res.msg, offset: 100, duration: 2000 })
        }
        if (res.code === 1 && res.data !== '') {
          this.addressList = []
          res.data.forEach((item, key) => {
            this.listTableFlag = true // 显示地址列表
            this.arsObj = item
            this.arsObj.order = item.order
            this.arsObj.address = item.province + item.city + item.area + item.detail + item.name
            this.addressList[key] = this.arsObj
          })
        }
        if (this.addressList.length !== 0) {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].name === '' || this.addressList[i].phone === '' || this.addressList[i].address === '' || this.addressList[i].order === '') {
              return this.$message.warning('第' + (i + 1) + '条收件人数据不全')
            }
            if (!/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone)) {
              return this.$message.warning('第' + (i + 1) + '条收件人手机号有误')
            }
          }
        }
      })
    },
    disAddres2() {
      this.flag2 = true
      setTimeout(() => {
        this.flag2 = false
      }, 5000)
      if (this.addForm.address === '') {
        this.addressList = []
        return false
      }
      const addForm = this.addForm.address
      const manual = 'manual'
      getDisAddres({ address: addForm, op: manual }).then((res) => {
        if (res.code !== 1) {
          this.$notify.warning({ title: res.msg, offset: 90, duration: 3000 })
          return false
        } else {
          this.$notify.success({ title: res.msg, offset: 100, duration: 2000 })
        }
        if (res.code === 1 && res.data !== '') {
          res.data.forEach((item, index) => {
            if (!item.order) {
              res.data[index].order = this.randomOrder()
            }
          })
          this.addressList = []
          res.data.forEach((item, key) => {
            this.listTableFlag = true // 显示地址列表
            this.arsObj = item
            this.arsObj.order = item.order
            this.arsObj.address = item.province + item.city + item.area + item.detail + item.name
            this.addressList[key] = this.arsObj
          })
        }
        if (this.addressList.length !== 0) {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].name === '' || this.addressList[i].phone === '' || this.addressList[i].address === '' || this.addressList[i].order === '') {
              return this.$message.warning('第' + (i + 1) + '条收件人数据不全')
            }
            if (!/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone)) {
              return this.$message.warning('第' + (i + 1) + '条收件人手机号有误')
            }
          }
        }
      })
    },
    // 订单为空时生产随机单号
    randomOrder() {
      const date = new Date()
      const yar = date.getFullYear()
      const m = [date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1]
      const d = date.getDate()
      const h = date.getHours()
      const n = date.getMinutes()
      const s = date.getSeconds()
      const rand = Math.floor(Math.random() * (9999 - 1000)) + 1000
      const order = `${yar}${m}${d}${h}${n}${s}${rand}`
      return order
    },
    // 地址格式二
    addAddressTwo() {
      const v = this.arsObj.name + this.arsObj.phone + this.arsObj.province + this.arsObj.city + this.arsObj.area + this.arsObj.detail + this.arsObj.order
      const a = AddressParse(v)
      if (!this.arsObj.name || !this.arsObj.phone || !this.arsObj.province || !this.arsObj.city || !this.arsObj.area || !this.arsObj.detail || !this.arsObj.order) {
        this.$message({ message: '请完善信息', type: 'warning' })
      } else if (a.name === '' || a.phone === '' || a.province === '' || a.city === '' || a.area === '' || a.detail === '' || a.order === '') {
        this.$message({ message: '输入地址格式错误!', type: 'warning' })
      } else {
        this.listTableFlag = true // 显示地址列表
        // this.arsObj.order = this.arsObj.order ? this.arsObj.order : this.randomNo(3)
        this.arsObj.address = this.arsObj.province + this.arsObj.city + this.arsObj.area + this.arsObj.detail
        this.addressList.push(this.arsObj)
        this.arsObj = {}
      }
    },
    // 旗帜筛选 tb
    getOrderListByStoreNametb() {
      this.flag = true
      if (this.radio === 6) {
        const a = null
        this.getdingtb(a)
        this.addressList = this.newList
      } else {
        this.getdingtb(this.radio)
        this.addressList = this.newList
      }
      setTimeout(() => {
        this.flag = false
      }, 5000)
    },
    getdingtb(zhi) {
      this.newList = []
      if (!this.shopValue) {
        this.$message({ message: '请先勾选已经完成绑定的店铺', type: 'warning' })
      }
      getTaoBaoOrderList({ tbseller: this.shopValue, seller_flag: zhi }).then((res) => {
        if (res.code !== 1) return this.$notify.warning({ title: res.msg })
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.newList.push({
              name: res.data[i].receive.receiver_name,
              address: res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_oaid + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address,
              province: res.data[i].receive.receiver_state,
              city: res.data[i].receive.receiver_city,
              area: res.data[i].receive.receiver_district,
              phone: res.data[i].receive.receiver_mobile,
              detail: res.data[i].receive.receiver_address,
              order: res.data[i].receive.tid,
              oaid: res.data[i].receive.oaid,
              nick: res.data[i].receive.buyer_nick,
              title: res.data[i].oid.title,
              seller_flag: res.data[i].receive.seller_flag,
              ecode_id: res.data[i].receive.ecode_id,
              trades: res.data[i].receive.trades
            })
          }
        }
      })
    },
    // 旗帜筛选 jd  dy pdd
    getOrderListByStoreName() {
      this.flag = true
      if (this.radio === 6) {
        const a = null
        this.getding(a)
        this.addressList = this.newList
      } else {
        this.getding(this.radio)
        this.addressList = this.newList
      }
      setTimeout(() => {
        this.flag = false
      }, 5000)
    },
    getding(zhi) {
      this.newList = []
      if (!this.shopValue) {
        this.$message({ message: '请先勾选已经完成绑定的店铺', type: 'warning' })
      }
      getAllOrderList({ pddSeller: this.shopValue, seller_flag: zhi }).then((res) => {
        if (res.code !== 1) return this.$notify.warning({ title: res.msg })
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.newList.push({
              name: res.data[i].receive.receiver_name,
              address: res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address,
              province: res.data[i].receive.receiver_state,
              city: res.data[i].receive.receiver_city,
              area: res.data[i].receive.receiver_district,
              phone: res.data[i].receive.receiver_mobile,
              detail: res.data[i].receive.receiver_address,
              // name: '郭芳芳', // address: '天河区', // province: '广东省', // city: '广州市', // area: '东发过的fgdfgdfg', // phone: '13699998888', // detail: '54543543', // order: '11111',
              order: res.data[i].receive.tid,
              nick: res.data[i].buyer_nick,
              title: res.data[i].oid.title,
              seller_flag: res.data[i].receive.seller_flag,
              ecode_id: res.data[i].receive.ecode_id,
              trades: res.data[i].receive.trades
            })
          }
        }
      })
    },
    /* 以下为地址列表的方法 */
    // 重新上传
    uploadAgain() {
      this.listTableFlag = false
      this.addressList = []
      this.arsObj = {}
      this.editObj = {}
      switch (this.stepTwoActive) {
        case 0:
          this.templateImport = true
          break
        case 1:
          break
        case 2:
          this.textareaAddressValue = ''
          break
      }
    },
    // 信息正确,下一步
    nextStep() {
      const a = this.$refs.ad.offsetHeight // 顶部广告位的高度
      const o = this.$refs.stepThree.offsetTop // 距离父元素顶部的高度
      this.$refs.main.$parent.$parent.$refs.main.scrollTop = o - a
    },
    nextStep2() {
      const a = this.$refs.ad.offsetHeight // 顶部广告位的高度
      const o = this.$refs.stepThrees.offsetTop // 距离父元素顶部的高度
      this.$refs.main.$parent.$parent.$refs.main.scrollTop = o - a
    },

    // 店铺导入订单编辑
    handleOrderListEdit(index, row) {
      this.addressListItemIndex = index - 1
      this.dialogFormVisible = true
      const _row = row
      row = Object.assign({}, _row)
      this.editObj = row
    },
    handleOrderListDelete(index) {
      this.addressList.splice(index - 1, 1)
    },
    // 编辑
    handleEdit(index, row) {
      this.addressListItemIndex = index - 1
      this.dialogFormVisible = true
      const _row = row
      this.editObj = Object.assign({}, _row)
    },
    // 删除
    handleDelete(index, row) {
      this.addressList.splice(index - 1, 1)
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
    },
    // Dialog 确定
    dialogFormYes() {
      this.dialogFormVisible = false
      // 修改原数组地址
      this.addressList.splice(this.addressListItemIndex, 1, this.editObj)
    } /* 以下为礼品列表的方法 */, // 仓库筛选点击
    /* 以上为地址列表的方法 */
    storeItemClick(id, index) {
      if (this.stepOneActive === null) {
        this.$message({ message: '请先选择平台', type: 'warning' })
      } else {
        this.goodsLists = []
        this.storeActive = id
        this.storeListItem = this.storeList[index] // 仓库对象
        this.storeListItemName = this.storeList[index].name // 获取到仓库名
        this.storeListItemId = id // 获取到仓库ID
        this.getGoodsSearchGs() // 根据仓库获取礼品列表
      }
    },
    // 排序筛选点击
    sortItemClick(index) {
      this.sortActive = index
      switch (index) {
        case 0:
          this.goodsType = ''
          if (!this.storeListItemId) {
            this.allGoods()
          } else {
            this.getGoodsSearchGs()
          }
          break
        case 1:
          this.goodsType = 'goods_price'
          this.getGoodsSearchGs()
          break
        case 2:
          this.goodsType = 'goods_num'
          this.getGoodsSearchGs()
          break
        case 3:
          this.goodsType = 'goods_weight'
          this.getGoodsSearchGs()
          break
      }
    },
    // 添加礼品
    addGoods(item) {
      // 首先需要判断是否选择了平台
      if (this.stepOneActive == null) {
        return this.$message({ message: '请先选择平台', type: 'warning' })
      }
      if (this.storeActive == null) {
        return this.$message({ message: '请先选择仓库', type: 'warning' })
      }
      this.storageOptions = [] // 添加前清空
      this.num = 1
      this.lipinList = [] // 选中的礼品详情
      this.lipinList.push(item)
      this.dataFlag = true
      this.goodsItem = item
      this.fprice = this.storeListItem.deliver_price
      const v = {}
      v.value = this.storeListItem.id
      v.label = this.storeListItem.deliver_name + ' / ' + this.storeListItem.deliver_price + '元'
      v.freight_price = this.storeListItem.deliver_price
      this.storageOptions.push(v)
      this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
      this.fPrice = this.storageOptions[0].freight_price // 初始化运费
      getAreaList().then((res) => {
        this.areaList = res.data // 发件人地址列表  /* 获取到发件人列表 */
        for (let i = 0; i < this.areaList.length; i++) {
          if (this.areaList[i].status === '1') {
            this.areaListValue = this.areaList[i].name + ' ' + this.areaList[i].send_phone // 初始化默认发件人地址
            this.area_id = this.areaList[i].id // 初始化发件人地址id
            this.oid = this.areaList[i].oid //
            return
          }
        }
      })
    },
    // 分页 当前页
    goodsHandleCurrentChange(val) {
      this.goodsCurrentPage = val
      // this.allGoods()
      this.getGoodsSearchGs()
    },
    /* 以上为礼品列表的方法 */
    // 提交订单
    placeOrder() {
      this.flag = true
      // 判断编辑后的地址格式是否正确
      if (this.addressList.length !== 0) {
        for (let i = 0; i < this.addressList.length; i++) {
          if (this.addressList[i].name === '' || this.addressList[i].phone === '' || this.addressList[i].address === '') {
            this.$message.error('第' + (i + 1) + '条收件人数据不全')
            return false
          }
          // if (!(/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone))) {
          //   this.$message.error('第' + (i + 1) + '条收件人手机号有误')
          //   return false
          // }
        }
      }
      if (!this.dataFlag) {
        return false // this.$message.error('请选择礼品')
      } else if (this.addressList.length === 0) {
        this.$message.error('请至少填写一个收货地址')
      } else if (this.areaList.length === 0 || this.areaListValue === '') {
        this.$message.error('请选择发货人地址')
      } else {
        // 获取账户余额
        getUserMoney().then((res) => {
          if (res.code !== 1) return this.$message(res.msg)
          if (res.code === 1) {
            this.money = res.data.usermoney
          }
        })
        setTimeout(() => {
          this.flag = false
        }, 3000)
        const a = this.addressList
        const postData = {}
        postData.real_list = []
        for (let i = 0; i < a.length; i++) {
          const item = {}
          item.third_order_no = a[i].order
          item.receive_name = a[i].name
          item.receive_phone = a[i].phone
          item.receive_province = a[i].province
          item.receive_city = a[i].city
          item.receive_town = a[i].area
          item.receive_address = a[i].detail
          item.receive_oaid = a[i].oaid
          item.ecode_id = a[i].ecode_id
          item.trades = a[i].trades
          if (a[i].lp_reissue_sn) {
            item.lp_reissue_sn = a[i].lp_reissue_sn
          } else {
            item.lp_reissue_sn = null
          }
          postData.real_list.push(item)
        }
        postData.goods_id = this.goodsItem.id
        postData.storage_id = this.storeListItemId
        postData.order_tips = this.remarks
        postData.goods_num = this.num
        postData.area_id = this.area_id
        postData.oaid = this.oaid
        postData.ecode_id = this.ecode_id
        postData.trades = this.trades
        // eslint-disable-next-line eqeqeq
        if (this.lurufangshi == '4' && this.platform2 == '1') {
          // 京东店铺导入
          postData.platform = 2 // 京东平台
        } else {
          postData.platform = this.platform2
        }
        postData.key = this.keys
        postData.order_from = this.order_from
        postData.shopValue = this.shopValue
        if (this.shopValue) {
          const _this = this
          this.shopOptions.forEach(function (item) {
            if (item.value === _this.shopValue) {
              postData.order_from = item.label
            }
          })
        }
        if (this.platform2 === '0' && this.lurufangshi === '2') {
          postData.order_type = 2
        } else if (this.platform2 === '3' && this.lurufangshi === '3') {
          postData.order_type = 3
        } else {
          postData.order_type = this.lurufangshi
        }
        createOrder(postData).then((res) => {
          if (res.code !== 1) this.$notify.success({ title: res.msg, offset: 50, duration: 15000 })
          if (res.code === 1) {
            this.orderId = res.data.order_id
            this.lp_reissue_sn = res.data.lp_reissue_sn
            this.dialogShow = true
            this.$notify.success({ title: res.msg, offset: 100, duration: 5000 })
          } else {
            return this.$notify.error({ title: res.msg, offset: 100, duration: 15000 })
          }
        })
      }
    },
    // 通过监听子组件发送过来的事件关闭Dialog// 重置
    closePayDialog() {
      this.$router.push('/orderAdmin/purchase')
      this.mainReload()
      this.inject()
    },
    // 停发区域Dialog关闭回调
    closeStopSending() {
      this.showStopSending = false
    },
    // 查看停发
    seeSending() {
      this.showStopSending = true
    },
    // 仓库选择框
    storageSelectChange(e) {
      this.fprice = e.freight_price
    },
    // 发件地址选择框// 改变发件人地址id
    areaSelectChange(e) {
      this.area_id = e
    },
    // 手工填写 ---> 地址格式切换
    handleClick() {
      this.listTableFlag = false
      this.arsObj = {}
      this.addressList = []
    },
    // 随机生成订单编号
    randomNo(j) {
      var randomno = ''
      for (var i = 0; i < j; i++) {
        randomno += Math.floor(Math.random() * 10)
      }
      randomno = new Date().getTime() + randomno
      return randomno
    },
    // 新建地址点击
    mesBox() {
      this.$confirm('前往添加发件地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push('/orderAdmin/sendAddress')
      })
    },
    /** * 模板导入 * 自定义上传 */
    uploadFile(file) {
      importExcel(file.file).then((res) => {
        if (res.code === 1) {
          for (let i = 0; i < res.data.length; i++) {
            this.arsObj.name = res.data[i].name
            this.arsObj.phone = res.data[i].phone
            this.arsObj.province = res.data[i].province
            this.arsObj.city = res.data[i].city
            this.arsObj.area = res.data[i].area
            this.arsObj.detail = res.data[i].detail
            this.arsObj.order = res.data[i].order
            this.addressList.push(this.arsObj)
            this.arsObj = {}
          }
          this.listTableFlag = true // 显示地址列表
          this.templateImport = false // 上传成功隐藏模板导入
          this.$message({ message: res.msg, type: 'success', duration: 20000 })
        } else {
          return this.$message.error(res.msg)
        }
      })
    },
    // 广告点击
    adClick() {
      window.open(this.clickhref, '_blank')
    },
    // 插件下载
    crxClick() {
      window.open(this.$store.getters.getSiteInfo.download_plugins, '_blank')
    },
    jdClick() {
      window.open(this.$store.getters.getSiteInfo.jd_course, '_blank')
    },
    pddClick() {
      window.open(this.$store.getters.getSiteInfo.pdd_course, '_blank')
    },
    // handleChange(value) {
    handleChange(value, scope) {
      this.num = value
    }
  },
  computed: {
    // 计算礼品总额
    totalGifts() {
      if (this.addressList.length !== 0) {
        const n = parseFloat(this.goodsItem.goods_price * this.num || 0.0) * parseFloat(this.addressList.length)
        return n
      } else {
        const m = parseFloat(this.goodsItem.goods_price * this.num) || 0.0
        return m || 0.0
      }
    },
    // // 计算运费
    freight() {
      if (this.addressList.length !== 0) {
        const f = parseFloat(this.fprice || 0.0) * parseFloat(this.addressList.length)
        return f
      } else {
        const y = parseFloat(this.fprice) || 0.0
        return y || 0.0
      }
    },
    // 计算应付金额  {{Number(goods.salePrice).toFixed(2)}}
    payable() {
      const t = parseFloat(this.totalGifts)
      const e = parseFloat(this.freight)
      return t + e
    },
    // 总重量
    totalWeigth() {
      const weight = parseFloat(this.goodsItem.goods_weight) * this.num
      return weight
    },
    // 小计
    littleTotal() {
      const subtotal = parseFloat(this.goodsItem.goods_price) * parseFloat(this.num)
      return subtotal
    }
  },
  // 处理插件跳转过来选定店铺
  mounted() {
    const that = this
    const op = that.$route.query.op
    // that.shopId = that.$route.query.shopId
    this.stepTwoActiveShop = -1 // 锁定一键
    this.lurufangshi = -1 // 录入方式
    if (!that.$route.query.shopId) {
      that.$router.push('/orderAdmin/purchase')
    }
    if (op) {
      var num = 0
      var timmer = setInterval(function () {
        if (num < 10) {
          num++
          if (!that.addForm.address) {
            if (that.$refs.seTxt1.value !== undefined) {
              var v = that.$refs.seTxt1.value
            }
            if (v) {
              that.addForm.address = that.$refs.seTxt1.value
              that.addForm.address += '\n'
            }
          } else {
            if (that.addForm.address) {
              clearInterval(timmer)
              that.disAddres()
            }
          }
        } else {
          clearInterval(timmer)
        }
      }, 1000)
      if (op === 'jd') {
        that.stepOneItemClick(2)
      } else if (op === 'tb') {
        that.stepOneItemClick(0)
      }
    }
    if (that.$route.query.shopId && this.$route.query.item !== '[object Object]') {
      that.lurufangshi = '1'
      // that.storeListItemId = that.$route.query.shopId.goods_id
    }
  }
}
</script>
<style scoped src="styles/views/orderAdmin/purchase.css">
.el-message {
  top: 20px !important;
}
.el-message:not(:last-child) {
  visibility: hidden;
}
/* .el-notification__title { top: 50px !important; } .el-notification__title:not(:last-child){ visibility: hidden; } */
</style>
<style scoped lang="scss">
.el-input {
  width: 450px;
}
.el-input__inner {
  height: 30px;
}
.el-button[data-v-11c101ee] {
  width: 150px;
  margin-right: 20px;
}
.el-select[data-v-11c1012b] {
  width: 450px;
}
/* Dialog */
//  .editForm .el-input {
//   width: 100%;
// }
// .list {
//   flex: 1;
//   padding-left: 16px;
//   list-style: none;
//   padding-left: 16px;
//   max-height: 230px;
//   overflow: auto;
// }
</style>
