<template>
  <div class="content">
    <el-dialog title="停发地区" :visible.sync="dialogVisible" @close="closeDialog" @opened="openedDialog" width="35%">
      <!-- 导航 -->
      <div class="box" ref="box">
        <span class="leftClick">
          <el-button @click="leftClick" :disabled="disabledLeft" type="text"><i class="el-icon-arrow-left"></i></el-button>
        </span>
        <div class="box_menu" ref="menu">
          <el-menu class="sending_menu" :default-active="defaultActive" mode="horizontal">
            <el-menu-item @click="storeItemClick(item.id)" v-for="(item, index) in stopStoreList" :key="index" :index="index + ''">{{ item.name }}</el-menu-item>
          </el-menu>
        </div>
        <span class="rightClick">
          <el-button @click="rightClick" :disabled="disabledRight" type="text"><i class="el-icon-arrow-right"></i></el-button>
        </span>
      </div>
      <!-- 表格 -->
      <div>
        <el-table border :data="tableData" style="width: 100%" :header-cell-style="handMerge" >
           <el-table-column prop="province" label="地区"> </el-table-column>
      <el-table-column prop="reason" label="原因"> </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="10"> </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { stopArea } from 'network/api'

export default {
  data () {
    return {
      dialogVisible: false,
      defaultActive: '0',
      boxOffsetWidth: '',
      menuOffsetWidth: '',
      moving: 0,
      storeId: '',
      disabledLeft: true,
      disabledRight: false,
      tableData: []
    }
  },
  props: {
    showStopSending: { type: Boolean, default: false },
    stopStoreList: { type: Array }
  },
  methods: {
    // 点击停发仓库
    storeItemClick (id) {
      this.storeId = id
      this.getStopArea()
    },
    handMerge ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 1) {
        return { display: 'none' }
      }
    },
    openedDialog () {
      this.boxOffsetWidth = this.$refs.box.offsetWidth - 60 // box宽度
      this.menuOffsetWidth = this.$refs.menu.offsetWidth // menu宽度
      if (this.menuOffsetWidth <= this.boxOffsetWidth) {
        this.disabledRight = true
      } else {
        this.disabledRight = false
      }
      // 初始化仓库id
      // this.storeId = this.stopStoreList[0].id
      this.defaultActive = '0'
      this.moving = 0
      this.getStopArea()
    },
    closeDialog () {
      this.defaultActive = '0'
      this.$emit('closeStopSending')
    },
    // 左箭头点击滑动
    leftClick () {
      this.disabledRight = false
      this.moving -= 250
      if (this.moving <= 0) {
        this.moving = 0
        this.disabledLeft = true
      }
      this.$refs.menu.style.transform = 'translate3d(' + this.moving + 'px, 0px, 0px)'
    },
    // 右箭头点击滑动
    rightClick () {
      if (this.menuOffsetWidth <= this.boxOffsetWidth) {
        return false
      }
      this.disabledLeft = false
      this.moving += 250
      if (this.moving >= this.menuOffsetWidth - this.boxOffsetWidth) {
        this.moving = this.menuOffsetWidth - this.boxOffsetWidth
        this.disabledRight = true
      }
      this.$refs.menu.style.transform = 'translate3d(' + -this.moving + 'px, 0px, 0px)'
    },
    // 根据仓库id请求列表
    getStopArea () {
      stopArea(this.storeId).then((res) => {
        // console.log(res)
        if (res.code !== 1) {
          return this.$message(res.msg)
        }
        if (res.code === 1) {
          this.tableData = res.data
        }
      })
    }
  },
  watch: {
    showStopSending (val) {
      this.dialogVisible = val
    }
  }
}
</script>

<style scoped src="styles/components/stopSending.css">
</style>

<style>
.content .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}

.sending_menu.el-menu {
  display: flex;
}

.sending_menu.el-menu.el-menu--horizontal {
  border: none;
}

.box .el-button--text {
  width: 100%;
  height: 100%;
  color: #909399;
}
</style>
